import React, { useState, useRef, useEffect } from 'react';
import ContentEditableDiv from '../ContentEditableDiv/ContentEditableDiv';
import { IBookChapter } from '../types';
import { ReactComponent as ThreeDots } from '../../../Assets/threeDots.svg';
import { ReactComponent as ChapterDelete } from '../../../Assets/chapterDelete.svg';
import { ReactComponent as ChapterAdd } from '../../../Assets/chapterAdd.svg';

import { ReactComponent as ChapterDragAndDrop } from '../../../Assets/chapterDragAndDrop.svg';
import arrow from '../../../Assets/icons/arrow.svg';
import pencil from '../../../Assets/pencil.svg';
import checkMark from '../../../Assets/whiteCheckMark.svg';

import s from './ChapterItem.module.scss';
import DropdownBox from 'Components/Common/dropdownBox/DropdownBox';
import clsx from 'clsx';
import TextArea from '../TextArea/TextArea';
import Button from 'Components/Common/Button/Button';
import Spinner from 'Components/Common/Spinner/Spinner';
import { rxActiveChapterIndex } from 'rx/rxState';

interface IProps {
  chapter: IBookChapter;
  index: number;
  onChapterTitleEditedProps: (value: string, index: number) => void;
  activeChapter: number;
  setActiveChapter: (index: number) => void;
  deleteChapter: (index: number) => void;
  onChapterAdd: (indexBefore: number, action: 'add' | 'insert') => void;
  loadingAddChapter: boolean;
  onNoteAdd: (chapterIndex: number, noteText: string) => void;
  isEditVersion: boolean;
  isGeneratedAllBookEnd: boolean;
  indexGenarateChapterText?: number;
  isBookTitleActive: boolean;
  setIsBookTitleActive: (arg: boolean) => void;
}

function ChapterItem({
  chapter,
  index,
  onChapterTitleEditedProps,
  activeChapter,
  setActiveChapter,
  deleteChapter,
  onChapterAdd,
  loadingAddChapter,
  onNoteAdd,
  isEditVersion,
  isGeneratedAllBookEnd,
  indexGenarateChapterText,
  isBookTitleActive,
  setIsBookTitleActive,
}: IProps) {
  const [chapterTitleInput, setChapterTitleInput] = useState<string>(
    chapter.title
  );
  const [isDotsMenuOpen, setIsDotsMenuOpen] = useState<boolean>(false);
  const [noteTextAreaInput, setNoteTextAreaInput] = useState<string>(
    chapter.comments[0]
  );
  const [isNoteTextAreaOpen, setIsNoteTextAreaOpen] = useState<boolean>(false);
  const [isHasNote, setIsHasNote] = useState<boolean>(false);

  const refDotsMenu = useRef(null);

  const onChapterTitleEdited = (value: string) => {
    onChapterTitleEditedProps(value, index);
  };

  const handleChangeNote = () => {
    if (noteTextAreaInput) {
      onNoteAdd(index, noteTextAreaInput.trim());
      setIsNoteTextAreaOpen(false);
    } else {
      onNoteAdd(index, '');
      setIsNoteTextAreaOpen(false);
    }
  };

  useEffect(() => {
    if (chapter.comments[0] && chapter.comments[0].trim().length > 0) {
      setIsHasNote(true);
    } else {
      setIsHasNote(false);
    }
  }, [chapter.comments]);

  useEffect(() => {
    if (isNoteTextAreaOpen && activeChapter != index) {
      handleChangeNote();
    }
  }, [isNoteTextAreaOpen, activeChapter]);

  const onChapterClick = () => {
    if (isGeneratedAllBookEnd) {
      setActiveChapter(index);
      setIsBookTitleActive(false);
      if (isEditVersion) {
        rxActiveChapterIndex.next(index + 1);
      }
    }
  };

  return (
    <div
      className={clsx({
        [s.chapterItemWrapper]: !isEditVersion,
        [s.chapterItemWrapperActive]: index === activeChapter && !isEditVersion,
        [s.chapterItemWrapperEditVersionActive]:
          index === activeChapter && isEditVersion && !isBookTitleActive,
        [s.chapterItemWrapperEditVersion]: isEditVersion,
      })}
    >
      <div
        className={clsx({
          [s.chapterItem]: !isEditVersion,
          [s.chapterItemEditVersion]: isEditVersion,
          [s.chapterItemActiveEditVersion]:
            index === activeChapter && isEditVersion && !isBookTitleActive,
        })}
        onClick={() => onChapterClick()}
      >
        <div
          className={clsx(s.chapterIndexCircle, {
            [s.chapterIndexCircleEditVersion]: isEditVersion,
            [s.chapterIndexCircleNoneDisabled]: !isEditVersion,
            [s.chapterIndexCircleNoneDisabled]:
              !isGeneratedAllBookEnd &&
              indexGenarateChapterText &&
              indexGenarateChapterText >= index,
            [s.chapterIndexCircleGenerated]:
              !isGeneratedAllBookEnd && indexGenarateChapterText === index,
            [s.chapterIndexCircleGeneratedEnd]:
              isEditVersion && isGeneratedAllBookEnd,
          })}
        >
          {!isGeneratedAllBookEnd && indexGenarateChapterText === index ? (
            <Spinner size={25} />
          ) : (
            index + 1
          )}
        </div>
        <div
          className={clsx({
            [s.chapterTitleInputEditVersion]: isEditVersion,
          })}
        >
          <ContentEditableDiv
            name={chapterTitleInput}
            fontSize={isEditVersion ? 14 : 16}
            lineHeight={isEditVersion ? 20 : 22}
            fontWeight={500}
            width={isEditVersion ? 180 : 270}
            height={40}
            onChange={!isGeneratedAllBookEnd ? () => {} : setChapterTitleInput}
            onEdit={!isGeneratedAllBookEnd ? () => {} : onChapterTitleEdited}
            onClick={() => onChapterClick()}
            isMultiline={true}
          />
        </div>
        <ChapterDragAndDrop
          className={clsx({
            [s.chapterDragAndDropSvg]: !isEditVersion,
            [s.chapterDragAndDropSvgEditVersion]: isEditVersion,
          })}
        />
        {!isEditVersion && (
          <div
            className={s.noteChangeButton}
            onClick={() => setIsNoteTextAreaOpen(!isNoteTextAreaOpen)}
          >
            {isHasNote ? (
              <>
                Click to Edit Note
                <img src={pencil} alt="pencil" />
              </>
            ) : (
              <>
                Click to add a Note
                <img src={arrow} alt="arrow" />
              </>
            )}
          </div>
        )}
        <ThreeDots
          className={clsx({
            [s.threeDotsSvg]: !isEditVersion,
            [s.threeDotsSvgEditVersion]: isEditVersion,
          })}
          ref={refDotsMenu}
          onClick={() => setIsDotsMenuOpen(true)}
        />
        <DropdownBox
          isOpen={isDotsMenuOpen}
          onClose={() => setIsDotsMenuOpen(false)}
          pointerEvents={true}
          componentRef={refDotsMenu}
          height={70}
        >
          <div className={s.dotsMenuBlock}>
            {!isEditVersion && (
              <>
                <div
                  className={
                    loadingAddChapter || !isGeneratedAllBookEnd
                      ? s.cursorDisabled
                      : ''
                  }
                  onClick={
                    loadingAddChapter || !isGeneratedAllBookEnd
                      ? () => {}
                      : () => {
                          onChapterAdd(index, 'insert');
                          setIsDotsMenuOpen(false);
                        }
                  }
                >
                  <ChapterAdd /> Add New Above
                </div>
                <div
                  className={
                    loadingAddChapter || !isGeneratedAllBookEnd
                      ? s.cursorDisabled
                      : ''
                  }
                  onClick={
                    loadingAddChapter || !isGeneratedAllBookEnd
                      ? () => {}
                      : () => {
                          onChapterAdd(index + 1, 'insert');
                          setIsDotsMenuOpen(false);
                        }
                  }
                >
                  <ChapterAdd /> Add New Below
                </div>
              </>
            )}
            <div
              className={
                loadingAddChapter || !isGeneratedAllBookEnd
                  ? s.cursorDisabled
                  : ''
              }
              onClick={
                loadingAddChapter || !isGeneratedAllBookEnd
                  ? () => {}
                  : () => {
                      deleteChapter(index);
                      setIsDotsMenuOpen(false);
                    }
              }
            >
              <ChapterDelete /> Delete
            </div>
          </div>
        </DropdownBox>
      </div>
      {isNoteTextAreaOpen && !isEditVersion && (
        <div className={s.textAreaBlock}>
          <TextArea
            label=""
            onChange={setNoteTextAreaInput}
            value={noteTextAreaInput}
            height={90}
          />
          <Button
            size="regular"
            onClick={() => handleChangeNote()}
            borderRadius={'25px'}
          >
            Add Note <img src={checkMark} alt="check mark" />
          </Button>
        </div>
      )}
      {!isNoteTextAreaOpen && isHasNote && !isEditVersion && (
        <div className={s.noteBlock}>{chapter.comments[0]}</div>
      )}
    </div>
  );
}

export default ChapterItem;
