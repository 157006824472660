import Spinner from 'Components/Common/Spinner/Spinner';
import React, { useState, useRef, useEffect } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

import ChapterItem from '../ChapterItem/ChapterItem';
import ContentEditableDiv from '../ContentEditableDiv/ContentEditableDiv';
import { IBookChapter } from '../types';
import s from './BookInfoBlock.module.scss';
import { ReactComponent as AddNewChapter } from '../../../Assets/addNewChapter.svg';
import Button from 'Components/Common/Button/Button';
import clsx from 'clsx';
import ChapterPlaceholders from '../ChapterPlaceholders/ChapterPlaceholders';
import { rxActiveChapterIndex } from 'rx/rxState';

interface IProps {
  bookTitle: string;
  onBookTitleEdited: (value: string) => void;
  onChapterTitleEdited: (value: string, index: number) => void;
  chapters: IBookChapter[];
  loadingChapter: boolean;
  loadingAddChapter: boolean;
  deleteChapter: (index: number) => void;
  onChapterAdd: (indexBefore: number, action: 'add' | 'insert') => void;
  reorderChapter: (dragIndex: number, dropIndex: number) => void;
  endReorderChapter: () => void;
  onNoteAdd: (chapterIndex: number, noteText: string) => void;
  onGenerateBook: (needRedirect: boolean) => void;
  isEditVersion: boolean;
  isGeneratedAllBookEnd: boolean;
  indexGenarateChapterText?: number;
}

function BookInfoBlock({
  bookTitle,
  onBookTitleEdited,
  chapters,
  loadingChapter,
  onChapterTitleEdited,
  deleteChapter,
  onChapterAdd,
  reorderChapter,
  endReorderChapter,
  loadingAddChapter,
  onNoteAdd,
  onGenerateBook,
  isEditVersion,
  isGeneratedAllBookEnd,
  indexGenarateChapterText,
}: IProps) {
  const [bookTitleInput, setBookTitleInput] = useState<string>(bookTitle);
  const [activeChapter, setActiveChapter] = useState<number>(0);
  const [isBookTitleActive, setIsBookTitleActive] = useState<boolean>(false);

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [lastSortingTimestamp, setLastSortingTimestamp] = useState<
    number | null
  >(null);

  useEffect(() => {
    setBookTitleInput(bookTitle);
  }, [bookTitle]);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      const currentTime = Date.now();
      if (lastSortingTimestamp && currentTime - lastSortingTimestamp > 2000) {
        endReorderChapter();
      }
    }, 2000);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [lastSortingTimestamp]);

  const onTitleInputClick = () => {
    setIsBookTitleActive(true);
    if (isEditVersion) {
      rxActiveChapterIndex.next(0);
    }
  };

  const handleChapterSorting = (res: DropResult) => {
    const { destination, source, draggableId } = res;

    if (!destination) {
      return;
    }
    if (isGeneratedAllBookEnd) {
      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }
      setLastSortingTimestamp(Date.now());
      reorderChapter(source.index, destination.index);
    }
  };
  return (
    <div>
      <div
        className={clsx({
          [s.bookInfoEditNameInput]: !isEditVersion,
          [s.bookInfoEditNameInputEditVersion]: isEditVersion,
          [s.bookInfoEditNameInputEditVersionActive]:
            isEditVersion && isBookTitleActive,
        })}
      >
        {!isEditVersion && (
          <div className={s.bookInfoEditNameInputHeader}>Your Book Title</div>
        )}
        <ContentEditableDiv
          name={bookTitleInput}
          fontSize={isEditVersion ? 14 : 30}
          lineHeight={isEditVersion ? 20 : 42}
          fontWeight={500}
          width={isEditVersion ? 300 : 500}
          height={isEditVersion ? 50 : 37}
          onChange={!isGeneratedAllBookEnd ? () => {} : setBookTitleInput}
          onEdit={!isGeneratedAllBookEnd ? () => {} : onBookTitleEdited}
          onClick={() => onTitleInputClick()}
        />
      </div>
      <DragDropContext onDragEnd={handleChapterSorting}>
        <Droppable droppableId="chapters">
          {(provided) => (
            <div
              className={clsx({
                [s.chaptersBlock]: !isEditVersion,
                [s.chaptersBlockEditVersion]: isEditVersion,
              })}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {loadingChapter ? (
                <ChapterPlaceholders />
              ) : (
                chapters.map((chapter: IBookChapter, index) =>
                  !isEditVersion ? (
                    <Draggable
                      key={chapter.title + index.toString()}
                      draggableId={`chapter-${index}`}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ChapterItem
                            index={index}
                            chapter={chapter}
                            onChapterTitleEditedProps={onChapterTitleEdited}
                            activeChapter={activeChapter}
                            setActiveChapter={setActiveChapter}
                            deleteChapter={deleteChapter}
                            onChapterAdd={onChapterAdd}
                            loadingAddChapter={loadingAddChapter}
                            onNoteAdd={onNoteAdd}
                            isEditVersion={isEditVersion}
                            isGeneratedAllBookEnd={isGeneratedAllBookEnd}
                            indexGenarateChapterText={indexGenarateChapterText}
                            isBookTitleActive={isBookTitleActive}
                            setIsBookTitleActive={setIsBookTitleActive}
                          />
                        </div>
                      )}
                    </Draggable>
                  ) : (
                    <ChapterItem
                      index={index}
                      chapter={chapter}
                      onChapterTitleEditedProps={onChapterTitleEdited}
                      activeChapter={activeChapter}
                      setActiveChapter={setActiveChapter}
                      deleteChapter={deleteChapter}
                      onChapterAdd={onChapterAdd}
                      loadingAddChapter={loadingAddChapter}
                      onNoteAdd={onNoteAdd}
                      isEditVersion={isEditVersion}
                      isGeneratedAllBookEnd={isGeneratedAllBookEnd}
                      indexGenarateChapterText={indexGenarateChapterText}
                      isBookTitleActive={isBookTitleActive}
                      setIsBookTitleActive={setIsBookTitleActive}
                    />
                  )
                )
              )}
              {!loadingChapter && !isEditVersion && (
                <div
                  className={clsx({
                    [s.addNewChapter]: !isEditVersion,
                    [s.addNewChapterEditVersion]: isEditVersion,
                  })}
                  onClick={() => onChapterAdd(chapters.length, 'add')}
                >
                  {loadingAddChapter || !isGeneratedAllBookEnd ? (
                    !isGeneratedAllBookEnd ? null : (
                      <Spinner size={isEditVersion ? 25 : 30} />
                    )
                  ) : (
                    <AddNewChapter />
                  )}
                </div>
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {!loadingChapter && !isEditVersion && (
        <div className={s.generateBookButtonsBlock}>
          <Button
            size="regular"
            color="accent"
            onClick={() => onGenerateBook(true)}
            borderRadius={'25px'}
          >
            Generate
          </Button>
        </div>
      )}
    </div>
  );
}

export default BookInfoBlock;
