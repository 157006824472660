import React, { Component } from 'react';
import Overlay from './Overlay';
import styled from 'styled-components';
import domtoimage from 'dom-to-image';
import { rxActiveChapterIndex, rxBlockIndexForSectionsPopup, rxBookDataForSave, rxBookNeedSave, rxChapterIndexForDelete, rxChapterRenameData, rxNewChapterIndex } from 'rx/rxState';

import { Block } from './Blocks/Block';
import { BlockText } from './Blocks/BlockText';
import { BlockTextCover } from './Blocks/BlockTextCover';
import { BlockVideo } from './Blocks/BlockVideo';
import { BlockForm } from './Blocks/BlockForm';
import { BlockImage } from './Blocks/BlockImage';
import { BlockButton } from './Blocks/BlockButton';
import { BlockSection } from './Blocks/BlockSection';
import { BlockCustom } from './Blocks/BlockCustom';
import { BlockTimer } from './Blocks/BlockTimer';
import { BlockColumn } from './Blocks/BlockColumn';
import { BlockRow } from './Blocks/BlockRow';
import { BlockStripe } from './Blocks/BlockStripe';
import { BlockPlaceholder } from './Blocks/BlockPlaceholder';

import blankPage from '../Assets/blankPage.svg'
import plusSvg from '../Assets/plus.svg'

import WebFont from 'webfontloader';

import {
    eventEmiter,
    rxViewWidth,
    rxBlocksFetched,
    rxBlocks,
    rxSelectedBlocks,
    rxViewZoom,
    rxAccentColor,
    rxCurrentPage,
    rxCreationData,
    rxExitModalData,
    rxShowExitModal,
    rxExitModalEnabled,
    rxProducts,
    rxStripeKey,
    rxIsLoading
} from '../rx/rxState';
import { sendUserEvent } from '../rx/actions/rxUserEvent';
import { VIEW_STATUSES, VIEW_TYPES, switchStatus } from '../rx/actions/rxViewStatus';
import { isToolbarAction } from '../utils/functions';
import { FontFamilies } from 'Components/TextEditor/FontStyleControls';
import Button from 'Components/Common/Button/Button';

const CanvasStyle = styled.canvas`
  position: absolute;
  z-index: 500;
  pointer-events: none;
  left: 0px;
  top: 0px;

  ${props => props.visible ? null : 'display: none;'};
  ${props => props.freeze && 'pointer-events: none;'}
`
const ViewStyle = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  background: ${props => props.backgroundColor};
  width: calc(${props => props.width} + 2px);

  &.modal {
    min-height: auto;
  }

  &.regular {
    position: absolute;
    height: ${props => 100 / props.zoom}%;
    transform: translateX(-50%) scale(${props => props.zoom});
    transform-origin: top center;
    left: 50%;
  }

  &.preview {
    height: fit-content;
    min-height: 100%;
    width: ${props => props.width};
    margin: auto;
  }

  font-family: 'Quicksand', sans-serif;

  ${props => props.freeze && 'pointer-events: none;'}
`

class View extends Component {
    constructor(props) {
        super(props);

        this.canvas = React.createRef();
        this.viewRef = React.createRef();
        this.viewElement = null;
        this.overlay = null;
        this.state = {
            isMouseClicked: false,
            isMouseDragging: false,
            // isDropModeActive: false,
            scheduledEditing: false,
            modalView: null,
            viewWidth: 'Responsive',
            blocksFetched: false,
            blocks: [],
            currentPage: '',
            pageId: '',
            creationData: null,
            selectedBlocks: null,
            zoom: 1.0,
            isModalOpened: false,
            status: VIEW_STATUSES.IDLE,
            modalEnabled: false,
            modalData: []
        };

        this.blocks = [];
        this.canvasRect = { width: 0, height: 0 };
        this.rootRect = { width: 0, height: 0 };
        this.scheduledEditingBlock = null;

        this.onDragEnter = this.onDragEnter.bind(this);

        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.onWheel = this.onWheel.bind(this);
        this.updateCanavsWidth = this.updateCanavsWidth.bind(this);
        this.handleKeyboardClick = this.handleKeyboardClick.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.idCounter = 1;
        this.accentColor = '#ffffff';
        this.subscriptions = {};
    }

    updateCanvasSize = () => {
        var root = document.getElementById('root');
        this.rootRect = root.getBoundingClientRect();

        this.canvas.current.width = this.rootRect.width * devicePixelRatio;
        this.canvas.current.height = this.rootRect.height * devicePixelRatio;

        if (!this.viewElement) {
            this.viewElement = document.getElementById('view');
        }
        this.canvasRect = this.viewElement.getBoundingClientRect();
    }

    rectContainPoint(rect, x, y) {
        return rect.x <= x && x <= rect.x + rect.width &&
            rect.y <= y && y <= rect.y + rect.height;
    }

    sortSections(mousePos) {
        let ids = [];
        for (let block of this.blocks) {
            ids.push(block.id);
        }

        this.blocks.sort((a, b) => {
            let aCenter = mousePos.y;
            let bCenter = mousePos.y;
            if (!a.isDragging) {
                aCenter = a.worldRenderBRect.y + a.worldRenderBRect.height * 0.5;
            }
            if (!b.isDragging) {
                bCenter = b.worldRenderBRect.y + b.worldRenderBRect.height * 0.5;
            }
            return aCenter - bCenter;
        });

        for (let i = 0; i < ids.length; i++) {
            if (ids[i] !== this.blocks[i].id) {
                //IF IDS is changed we need to update view;
                this.setState({});
                break;
            }
        }
    }

    handleKeyboardClick(event) {
        this.state.isMouseClicked && event.preventDefault()
    }

    handleMouseDown() {
        this.setState({
            ...this.state,
            isMouseClicked: true
        })
    }

    handleMouseUp() {
        this.setState({
            ...this.state,
            isMouseClicked: false
        })
    }

    handleEvents(event) {
        switch (event.type) {
            case 'preview':
                this.savePreview();
                break;
            case 'save':
                rxIsLoading.next('Saving page...');
                if (this.props.modalView) {
                    rxExitModalData.next(this.pack(this.blocks));
                    return;
                } else {
                    rxBlocks.next(this.pack(this.blocks));
                    // this.saveToCache();
                }
                eventEmiter.next({
                    type: 'save-page',
                });
                break;
            case 'load-page':
                this.overlay.setBlocks([]);
                this.blocks = [];
                break;
            case 'push-history':
                // this.saveToCache();
                rxBlocks.next(this.pack(this.blocks));
                eventEmiter.next({ type: 'add-history' });
                break;
            case 'switch-view-exit-modal':
                eventEmiter.next({
                    type: 'switch-exit-view',
                    payload: {
                        value: true
                    },
                });
                this.overlay.clearSelection();
                if (!this.props.modalView) {
                    rxBlocks.next(this.pack(this.blocks));
                    switchStatus({
                        status: VIEW_STATUSES.DISABLED,
                        viewType: VIEW_TYPES.REGULAR,
                    });
                } else {
                    switchStatus({
                        status: VIEW_STATUSES.IDLE,
                        viewType: VIEW_TYPES.POPUP,
                    });
                }
                break
            case 'switch-view-main-blocks':
                eventEmiter.next({
                    type: 'switch-exit-view',
                    payload: {
                        value: false,
                    }
                });
                if (this.props.modalView) {
                    switchStatus({
                        status: VIEW_STATUSES.DISABLED,
                        viewType: VIEW_TYPES.POPUP,
                    });
                } else {
                    switchStatus({
                        status: VIEW_STATUSES.IDLE,
                        viewType: VIEW_TYPES.REGULAR,
                    });
                }
                break
            case 'change-view-status':
                this.handleChangedStatus(event.payload);
                break;
            case 'update-view':
                this.setState({});
                break;
            default:
                break;
        }
    }

    handleChangedAccentColor({ color }) {
        console.log('color: ', color);
    }

    handleChangedStatus({ status, viewType }) {
        if ((viewType === VIEW_TYPES.REGULAR && this.props.modalView)
            || (viewType === VIEW_TYPES.POPUP && !this.props.modalView)) {
            return;
        }

        this.setState({
            ...this.state,
            status
        });
    }

    handleDepthOrder(action) {
        const blocks = [...this.overlay.selectedBlocks];
        if (blocks && blocks.length > 0) {
            for (let block of blocks) {
                let parent = null;
                if (block.parent == null) {
                    parent = this.blocks;
                } else {
                    parent = block.parent.children;
                }
                let index = parent.indexOf(block);
                parent.splice(index, 1);
                switch (action) {
                    case 'BringFront': {
                        parent.splice(parent.length, 0, block);
                        break;
                    }
                    case 'SendBack': {
                        parent.splice(0, 0, block);
                        break;
                    }
                    default:
                        break
                }
            }
        }

        this.setState({
            ...this.state,
            status: VIEW_STATUSES.IDLE,
            selectedBlocks: null,
        })
    }
    renameChapterSection(sectionNumber, newText) {
        console.log(sectionNumber, newText);
    }

    updateCanavsWidth() {
        this.setState({});
    }

    setModalView(destination) {
        this.setState({ ...this.state, modalView: destination });
    }

    onModalViewClose() {
        this.setState({ ...this.state, modalView: null });
    }

    componentDidMount() {

        document.body.onresize = this.updateCanavsWidth;
        window.addEventListener('resize', this.updateCanavsWidth);
        this.updateCanvasSize();
        if (this.overlay == null) {
            this.overlay = Overlay.instance(this.props.id, {
                canvas: this.canvas.current,
                view: this,
                live: this.props.liveMode,
            });
            this.overlay.setBlocks(this.blocks);
            this.overlay.draw();
        }

        this.eventSubscription = eventEmiter.subscribe({
            next: (v) => this.handleEvents(v),
        });
        this.setState({});
        this.subscriptions.blocksFetched = rxBlocksFetched.subscribe({
            next: (v) => {
                this.setState({
                    ...this.state,
                    blocksFetched: v
                })
            }
        });
        this.subscriptions.viewWidth = rxViewWidth.subscribe({
            next: (v) => {
                this.setState({
                    viewWidth: v
                })
            }
        });
        this.subscriptions.viewZoom = rxViewZoom.subscribe({
            next: (v) => {
                this.overlay.setZoom(v)
            }
        });
        this.subscriptions.selectedBlocks = rxSelectedBlocks.subscribe({
            next: (v) => {
                let data = null;
                if (v.length > 0) {
                    data = v[0].pack()
                }
                window.top.postMessage({
                    target: 'view-iframe',
                    data: { name: 'selected-blocks', params: data }
                });
            }
        });
        // this.subscriptions.accentColor = rxAccentColor.subscribe({
        //     next: (v) => {
        //         this.accentColor = v;
        //         this.setStylingProperties();
        //     }
        // });
        this.subscriptions.blocks = rxBlocks.subscribe({
            next: (v) => {
                if (!this.props.modalView) {
                    this.setState({
                        ...this.state,
                        blocks: v
                    });
                    this.unpack(v);
                    this.overlay.setBlocks(this.blocks);
                }
            }
        });
        this.subscriptions.modalData = rxExitModalData.subscribe({
            next: (v) => {
                if (this.props.modalView) {
                    this.setState({
                        ...this.state,
                        blocks: v
                    });
                    this.unpack(v);
                    this.overlay.setBlocks(this.blocks);
                } else {
                    this.setState({
                        ...this.state,
                        modalData: v
                    });
                }
            }
        });
        this.subscriptions.currentPage = rxCurrentPage.subscribe({
            next: (v) => {
                this.setState({
                    ...this.state,
                    currentPage: v
                });
            }
        });
        this.subscriptions.pageId = rxCurrentPage.subscribe({
            next: (v) => {
                this.setState({
                    ...this.state,
                    pageId: v
                })
            }
        });
        this.subscriptions.creationData = rxCreationData.subscribe({
            next: (v) => {
                if (rxShowExitModal.getValue() === this.props.modalView) {
                    // this.setState({
                    //     ...this.state,
                    //     creationData: v
                    // });
                    const viewType = this.getViewType();
                    switchStatus({
                        status: VIEW_STATUSES.IDLE,
                        viewType
                    })
                    if (v.blockType === 'section') {
                        this.idCounter = this.overlay.addSectionFromData(v.data, this.idCounter, v.index);
                    }
                    // else if(v.blockType === null){
                    //     this.overlay.cancelSectionAdd();
                    // }
                    else {
                        this.overlay.adder.blockInfo = v;
                    }
                }
            }
        });
        this.subscriptions.modalOpened = rxShowExitModal.subscribe({
            next: (v) => {
                this.setState({
                    ...this.state,
                    isModalOpened: v,
                });


                if ((v && !this.props.modalView) || (!v && this.props.modalView)) {
                    this.setState({
                        ...this.state,
                        status: VIEW_STATUSES.DISABLED,
                    });
                }
            }
        })
        this.subscriptions.modalEnabled = rxExitModalEnabled.subscribe({
            next: (v) => {
                this.setState({
                    ...this.state,
                    modalEnabled: v,
                });
            }
        })
        this.subscriptions.newChapterPosition = rxNewChapterIndex.subscribe(newChapterIndex => {
            this.reorderChapter(newChapterIndex)
        });
        this.subscriptions.chapterIndexForDelete = rxChapterIndexForDelete.subscribe(deleteIndex => {
            if (deleteIndex || deleteIndex === 0) {
                this.deleteChapter(deleteIndex)
            }
        });
        this.subscriptions.bookNeedSave = rxBookNeedSave.subscribe(bookNeedSave => {
            if (bookNeedSave) {
                this.saveBook()
            }
        });
        this.subscriptions.activeChapterIndex = rxActiveChapterIndex.subscribe(indexForScroll => {
            if (this.blocks[indexForScroll] && this.blocks[indexForScroll].worldRenderBRect) {
                this.onScrollToChapter(this.blocks[indexForScroll].worldRenderBRect.top)
            }
        });
        this.subscriptions.chapterRenameData = rxChapterRenameData.subscribe(renameData => {
            if (this.blocks && renameData && this.blocks[renameData.index]) {
                this.renameChapterSection(renameData.index, renameData.newText)
            }
        });

        if (this.props.liveMode && !this.props.isBook) {
            sendUserEvent({ type: 'VIEW', projectId: this.props.productId });
        }

        //TODO: move it to central controller
        for (let font of FontFamilies) {
            WebFont.load({
                google: {
                    families: [font.label]
                }
            })
        }

    }

    update() {
        this.setState({});
    }

    needsLayout() {
        this.overlay.update();
        this.overlay.draw();
    }

    componentDidUpdate() {
        this.overlay.scrollTop = this.viewRef.current.scrollTop;
        this.updateCanvasSize();
        this.overlay.update();

        this.overlay.draw();
        // let creationData = this.state.creationData;
        // if (creationData) {
        //     this.overlay.creationData = creationData;
        // }

        if (this.state.scheduledEditing) {
            this.setState({
                ...this.state,
                scheduledEditing: false
            });
            const viewType = this.getViewType();
            switchStatus({
                status: VIEW_STATUSES.EDITING_TEXT,
                viewType
            })
            this.scheduledEditingBlock.setEditing(true);
        }
        if (this.state.status === VIEW_STATUSES.IDLE) {
            window.addEventListener("keypress", this.handleKeyboardClick)
            window.addEventListener("mousedown", this.handleMouseDown)
            window.addEventListener("mouseup", this.handleMouseUp)
            window.addEventListener("keydown", this.onKeyDown);
            window.addEventListener("keyup", this.onKeyUp);
        }
        eventEmiter.next({
            type: 'view-updated'
        })

    }

    reorderChapter(newIndexs) {
        if (this.blocks && newIndexs) {
            const result = [];
            if (this.blocks[0].image) {
                const imgElement = this.blocks[0];
                result.push(imgElement);
            }
            for (let i = 0; i < newIndexs.length; i++) {
                let index = newIndexs[i];
                if (this.blocks[0].image) {
                    index = index + 1
                }
                const element = this.blocks[index];
                result.push(element);
            }

            this.blocks = result;
            this.update();
            const bookData = this.pack()
            rxBookDataForSave.next(bookData)
        }
    }

    deleteChapter(deleteIndex) {
        if (this.blocks) {
            if (this.blocks[0].image) {
                this.blocks.splice(deleteIndex + 1, 1);
            } else {
                this.blocks.splice(deleteIndex, 1);
            }
            this.update()
            const bookData = this.pack()
            rxBookDataForSave.next(bookData)
        }
    }

    onScrollToChapter(top) {
        const view = document.getElementById('view')
        view.scrollTo({
            top: view.scrollTop + top,
            behavior: "smooth",
        });
    }

    saveBook() {
        const bookData = this.pack()
        rxBookDataForSave.next(bookData)
    }

    removeBlock(blockToRemove) {
        let blockIndex = this.blocks.findIndex(block => block.id === blockToRemove.id);
        this.blocks.splice(blockIndex, 1);
    }

    createBlock(type, id = this.idCounter) {
        this.idCounter += 1;
        switch (type) {
            case 'Block':
                return new Block({ id: id, canvas: this.canvas.current, view: this });
            case 'Text':
                return new BlockText({ id: id, canvas: this.canvas.current, view: this });
            case 'TextCover':
                return new BlockTextCover({ id: id, canvas: this.canvas.current, view: this });
            case 'Video':
                return new BlockVideo({ id: id, canvas: this.canvas.current, view: this });
            case 'Form':
                return new BlockForm({ id: id, canvas: this.canvas.current, view: this });
            case 'Image':
                return new BlockImage({ id: id, canvas: this.canvas.current, view: this });
            case 'Button':
                return new BlockButton({ id: id, canvas: this.canvas.current, view: this });
            case 'Section':
                let block = new BlockSection({ id: id, canvas: this.canvas.current, view: this });
                if (this.props.modalView) {// In case of modal view all Section should fit in to modal view
                    block.widthOverride = '100%'
                }
                return block;

            case 'Background'://TODO: depricated remove it eventually 
                return new BlockSection({ id: id, canvas: this.canvas.current, view: this });
            case 'Custom':
                return new BlockCustom({ id: id, canvas: this.canvas.current, view: this });
            case 'Timer':
                return new BlockTimer({ id: id, canvas: this.canvas.current, view: this });
            case 'Column':
                return new BlockColumn({ id: id, canvas: this.canvas.current, view: this });
            case 'Row':
                return new BlockRow({ id: id, canvas: this.canvas.current, view: this });
            case 'Stripe':
                return new BlockStripe({ id: id, canvas: this.canvas.current, view: this });
            case 'Placeholder':
                return new BlockPlaceholder({ id: id, canvas: this.canvas.current, view: this });

            default:
                break;
        }
    }

    createUnpackBlock(data) {
        let block = this.createBlock(data.type, data.id);
        try {
            block.unpack(data);
        } catch (error) {
            console.log('[Block] Unpack Error', error);
        }
        return block
    }

    addBlock(blockData) {
        let block = this.createBlock(blockData.type);
        block.unpack(blockData);
        this.blocks.push(block);

        this.setState({});
        this.overlay.update();

        return block;
    }

    getRootSectionFor(block) {
        if (block.parent) {
            if (block.parent.type === 'Section') {
                return block.parent;
            } else {
                return this.getRootSectionFor(block.parent);
            }
        } else {
            return null;
        }
    }

    getBlocksByType(type) {
        let blocks = [];
        for (let block of this.blocks) {
            blocks = blocks.concat(block.getBlocksByType(type));
        }
        return blocks
    }

    getBlocksByTag(tag) {
        let blocks = [];
        for (let block of this.blocks) {
            blocks = blocks.concat(block.getBlocksByTag(tag));
        }
        return blocks
    }

    getBlockAt(x, y, ignoreBlock) {
        for (let block of this.blocks) {
            let b = block.blockAtPoint(x, y, ignoreBlock);
            if (b) {
                return b;
            }
        }

        return null;
    }

    onWheel(e) {

    }

    onScroll(e) {
        this.overlay.scrollTop = e.target.scrollTop;
        this.setState({}); //TODO: optimize and avoide double update during scrolling

    }

    getBlockById(id) {
        for (let block of this.blocks) {
            let b = block.getBlockById(id);
            if (b) {
                return b;
            }
        }
        return null;
    }

    isMobileLayout() {
        // console.log("this.props.liveMode && this.canvasRect.width,", this.props.liveMode , this.canvasRect.width, this.props.id);
        if (this.props.liveMode && document.body.clientWidth < 500) {
            return true; //MOBILE
        }
        return this.state.viewWidth === 'Mobile';
    }
    getViewSize() {
        //TODO: need to geenrlize variables
        const lg_width = 1540;
        const md_width = 1080;
        const sm_width = 750;
        // $xs-width: 500; //NOT USED
        const width = this.canvasRect.width;
        // console.log('width:', width);

        if (width < sm_width) {
            return 'xs'
        }

        if (width < md_width) {
            return 'sm'
        }

        if (width < lg_width) {
            return 'lg'//'md' //TODO: remove this after get proof that we only need two sizes
        }

        if (width > lg_width) {
            return 'lg'
        }
    }

    getContainerWidth() {
        const sizes = {
            xs: '100%',
            sm: '750px',
            md: '1080px',
            lg: '1540px'
        }
        return sizes[this.getViewSize()]
    }


    getViewWidth() {
        if (this.props.width) {
            return `${this.props.width}px`
        }

        if (this.props.liveMode) {
            return '100%';
        }

        switch (this.state.viewWidth) {
            case 'Desktop':
                return '1024px';
            case 'Mobile':
                return '600px';
            case 'Responsive':
                return '100%';
            default:
                return '800px';
        }
    }

    pack() {
        let data = {};
        let blocks = [];
        for (let block of this.blocks) {
            let d = block.pack();
            blocks.push(d);
        }
        data['blocks'] = blocks;
        data['lastId'] = this.idCounter;
        data['accentColor'] = this.accentColor;
        data['aiSuggestion'] = this.aiSuggestion;

        // console.log("data:", JSON.stringify(data) );
        return data;


    }

    unpack(data) {
        console.log('unpack data for:', this.props.id);
        // console.log('data:', data)
        //Unpacking all Blocks
        this.blocks = [];
        if (!data['blocks']) {
            return;
        }
        for (let blockData of data['blocks']) {
            let block = this.createUnpackBlock(blockData)
            this.blocks.push(block);
        }
        if (data['lastId']) {
            this.idCounter = data['lastId'];
        }
        this.aiSuggestion = data['aiSuggestion'];
        if (data['accentColor']) {
            rxAccentColor.next(data['accentColor']);
        }

        // //Unpacking Connections;
        // for (let blockData of data['blocks']) {
        //     let block = this.getBlockById(blockData['id']);
        //     block.unpackConnections(blockData);
        // }

        this.overlay.update();
        this.update();

    }

    onKeyUp(event) {
        if (this.state.status !== VIEW_STATUSES.IDLE) {
            return;
        }
    }

    /**
     * @param {KeyboardEvent} event
     */

    canKeyEventsProceed(event) {
        if (event.target === window.document.body) {
            return true;
        }
        if (event.target.className.search('DraftEditor') >= 0) {
            let sel = window.getSelection();
            if (sel.isCollapsed) {
                return true;
            }
        }

        return false;
    }
    onKeyDown(event) {
        if (this.state.status !== VIEW_STATUSES.IDLE) {
            return;
        }
        var activeElement = document.activeElement.className;
        if (activeElement === 'input-input') {
            return;
        }

        if (event.key === 'Escape') {
            let creationData = this.state.creationData;
            if (creationData && creationData.enabled) {
                rxCreationData.next({
                    blockType: null
                })
            }
            this.setState({});
        }
        // console.log('KEY:', event.key, event.metaKey, event.code);

        if (event.code === 'Minus' && (event.ctrlKey || event.metaKey)) {
            event.preventDefault();
            this.overlay.setZoom(-1);
        }
        if (event.code === 'Equal' && (event.ctrlKey || event.metaKey)) {
            event.preventDefault();
            this.overlay.setZoom(+1);
        }
        if ((event.key === 'Delete' || event.key === 'Backspace') && !isToolbarAction(event)) {
            this.overlay.removeSelectedBlock();
        }
        else if (event.code === 'KeyE' && (event.ctrlKey || event.metaKey)) {
            console.log('exporting section');
            event.preventDefault();
            this.exportSection();
        } else if (event.code === 'KeyQ' && (event.altKey || event.metaKey)) { //workaround FOr windows
            console.log('exporting section');
            event.preventDefault();
            this.exportSection();
        } else if (event.code === 'KeyS' && (event.ctrlKey || event.metaKey)) {
            // TODO: reassign this action to make save in permanenet Storage instead of LocalStorage
            event.preventDefault();
            this.savePreview();
        }
        //TODO: move this in to dedicated place
        else if (event.code === 'KeyR' && (event.ctrlKey || event.metaKey)) {
            event.preventDefault();
            const block = this.createBlock('Row')
            const selectedBlock = this.overlay.manipulator.blocks[0];
            selectedBlock.children.push(block)
            this.setState({});
        }
        else if (event.code === 'KeyK' && (event.ctrlKey || event.metaKey)) {
            event.preventDefault();
            this.idCounter += 1
            const block = this.createBlock('Column')
            const selectedBlock = this.overlay.manipulator.blocks[0];
            selectedBlock.children.push(block)
            this.setState({});
        }
        else if (event.code === 'KeyC' && (event.ctrlKey || event.metaKey)) { //COPY
            if (this.canKeyEventsProceed(event)) {
                const selectedBlock = this.overlay.manipulator.blocks[0];
                const data = selectedBlock.pack();
                localStorage.setItem('BUFFER', JSON.stringify(data));
            }
        }
        else if (event.code === 'KeyV' && (event.ctrlKey || event.metaKey)) {
            if (this.canKeyEventsProceed(event)) {
                const blockData = JSON.parse(localStorage.getItem('BUFFER'));
                blockData.id = this.idCounter;
                let block = this.createUnpackBlock(blockData);

                const selectedBlock = this.overlay.manipulator.blocks[0];
                selectedBlock.addChild(block);

                this.setState({});
            }
        }

    }

    onDragEnter(event) {
        // if (this.state.isDropModeActive === false) {
        //     this.setState({
        //         ...this.state,
        //         isDropModeActive: true
        //     });
        // }

        event.preventDefault();
    }

    onDragLeave(event) {
        event.preventDefault();
    }

    onDragOver(event) {
        event.preventDefault();
    }

    onDrop(event) {
        // if (this.state.isDropModeActive === true) {
        //     this.setState({
        //         ...this.state,
        //         isDropModeActive: false
        //     });
        // }

        event.preventDefault();
    }

    savePreview() {
        console.log('SAVE PREVIEW FOR:', this.props.id);
        let data = this.pack();
        // rxBlocks.next(data);
        if (Object.entries(data).length !== 0) {
            data.exitpopupenabled = this.state.modalEnabled;
            data.stripePublicKey = rxStripeKey.getValue();
            data.products = rxProducts.getValue();
            // data.exitpopupdata = this.state.modalData;
            let modal = this.props.modalView ? '-modal' : '';
            const itemName = `liveData-${this.props.productId}-${this.props.page}` + modal;
            localStorage.setItem(itemName, JSON.stringify(data));
            console.log('save for key:', itemName);
        }
        // this.saveToCache();
    }

    componentWillUnmount() {

        this.eventSubscription.unsubscribe();
        for (const key in this.subscriptions) {
            this.subscriptions[key].unsubscribe();
        }
        window.removeEventListener('resize', this.updateCanavsWidth);
    }

    exportSection() {
        let selectedBlock = this.overlay.manipulator.blocks[0];
        if (selectedBlock && selectedBlock.type == 'Section') {
            console.log("Export Section");
            const data = selectedBlock.pack();
            const domNode = document.getElementById(selectedBlock.id);
            domtoimage.toPng(domNode)
                .then(function (dataUrl) {
                    eventEmiter.next({
                        type: 'save-section',
                        payload: {
                            data,
                            image: dataUrl
                        }
                    });
                })
        }
    }

    getStatus() {
        return this.state.status;
    }

    getViewType() {
        return this.props.modalView ? VIEW_TYPES.POPUP : VIEW_TYPES.REGULAR;
    }

    render() {
        let className = 'modal';
        if (!this.props.modalView) {
            className = 'regular';
        }
        if (!this.props.modalView && this.props.liveMode) {
            className = 'preview';
        }

        let sizeStyle = {}
        if (this.canvas.current) {
            sizeStyle = {
                width: this.canvas.current.width / devicePixelRatio,
                height: this.canvas.current.height / devicePixelRatio
            }
        }

        return <>
            <ViewStyle
                id='view'
                key='view'
                className={className}
                ref={this.viewRef}
                onDragEnter={this.onDragEnter}
                onDragLeave={this.onDragLeave}
                onDragOver={this.onDragOver}
                onDrop={this.onDrop}
                onScroll={this.onScroll}
                onWheel={this.onWheel}
                // onMouseDown={(e)=>this.onMouseDown(e)}
                width={this.getViewWidth()}
                backgroundColor='white'
                zoom={this.props.zoom || this.state.zoom}
                freeze={this.state.status === VIEW_STATUSES.DISABLED}
            >
                {this.blocks.map((block) => block.renderView())}

                {(this.blocks.length === 0 && !this.props.liveMode) &&
                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', left: '0', top: '0', flexDirection: 'column' }}>
                        <img src={blankPage} alt='blank page img'></img>
                        <div style={{ fontFamily: 'Quicksand', fontWeight: '500', fontSize: '16px', color: '#A0A0A0', width: '230px', textAlign: 'center', marginTop: '30px', marginBottom: '30px' }}>
                            Click the button below to add your first section!
                        </div>
                        <Button
                            color="accent"
                            size='regular'
                            onClick={() => rxBlockIndexForSectionsPopup.next(0)}
                        >
                            <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                                <img src={plusSvg} alt='plus svg' style={{ marginRight: '5px' }}></img> Add Section
                            </div>
                        </Button>
                    </div>}
                {/* footer */}


            </ViewStyle>
            <CanvasStyle
                id='canvas'
                key='canvas'
                ref={this.canvas}
                width={0}
                height={0}
                visible={!this.props.liveMode && this.state.status !== VIEW_STATUSES.DISABLED}
                style={this.state.status === VIEW_STATUSES.EDITING_TEXT ? { pointerEvents: 'none' } + sizeStyle : sizeStyle}
                freeze={this.state.status === VIEW_STATUSES.DISABLED}
            />
        </>
    }
}

export default View;