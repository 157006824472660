import React, { useState, useRef } from 'react';
import s from './Select.module.scss';
import { UseOnClickOutside } from 'utils/UseOnClickOutside';
import arrow from '../../../Assets/icons/arrow.svg';
import checkMark from '../../../Assets/checkMark.svg';

interface IProps {
  value: string | null;
  onChange: (newValue: string) => void;
  label: string;
  options: string[];
}

const Select = (props: IProps) => {
  const [open, setOpen] = useState(false);
  const refSelect = useRef(null);

  UseOnClickOutside(refSelect, () => setOpen(false));

  const handleOpen = () => {
    setOpen(!open);
  }

  const handleSelect = (option: string) => {
    setOpen(false);
    props.onChange(option);
  }

  return (
    <div ref={refSelect}>
      <div className={s.select}>
        <span className={s.selected} style={open ? {borderColor:'#4957d8'} : {}} onClick={() => handleOpen()}>{props.value ? props.value : <span>{props.label}</span>} <img src={arrow} alt="arrow" /></span>
        {open && <ul className={s.optionsList}>
          {props.options.map((option, index) => (
            option != '<split>' ? 
            <li key={index} onClick={() => handleSelect(option)}>
              {option === props.value && <img src={checkMark} alt='check mark'/> }{option}
            </li>
            :
            <div className={s.optionSplit}>

            </div>
          ))}
        </ul>}
      </div>
    </div>
  )
}

export default Select;