import React from 'react';
import s from './Login.module.scss';
import Button from '../Common/Button/Button';
import { Auth } from 'aws-amplify';
import { username } from 'utils/api';
import { AUTH_USER_TOKEN_KEY, CONSULT_URL } from 'Constants';
import { Formik, Form } from 'formik';
import { FormInput } from 'Components/Common/Inputs/Input';
import * as Yup from 'yup';
import { graphQlCall } from '../../graphql/utils';
import QUERIES from '../../graphql/queries';

interface IValues {
  email: string;
  password: string;
}

interface IProps {
  onSubmit: (values: IValues) => void;
  onSwitchToSignUp: () => void;
}

const LogIn = (props: IProps) => {
  const handleSubmit = async (values: IValues) => {
    try {
      const cognitoResponse = await Auth.signIn(values.email, values.password);
      Document.prototype.cognitoUser = cognitoResponse;
      if (cognitoResponse.challengeName === "NEW_PASSWORD_REQUIRED") {
        const email = cognitoResponse.challengeParam.userAttributes.email;
        localStorage.setItem('USER_EMAIL', email);
        window.open(`${CONSULT_URL}/console/signup/${email}`, "_self");
        return;
      }

      const owner = username(cognitoResponse.signInUserSession.accessToken.jwtToken);
      const user = await graphQlCall({
        queryTemplateObject: QUERIES.GET_USER_DETAILS_QUERY,
        values: {
          owner
        }
      });
      localStorage.setItem('USER_ID', user._id);
      localStorage.setItem('USER_ROLE', user.role);
      if (user.subdomain) {
        localStorage.setItem('USER_SUBDOMAIN', user.subdomain);
      }
      localStorage.setItem(
        AUTH_USER_TOKEN_KEY,
        cognitoResponse.signInUserSession.accessToken.jwtToken
      );
      localStorage.setItem('USER_EMAIL', values.email);

      const projects = await graphQlCall({
        queryTemplateObject: QUERIES.FETCH_FUNNELS_QUERY,
        headerType: 'USER-AUTH'
      });
      if (projects.length === 0) {
        window.open(`${CONSULT_URL}/edit/generate`, "_self");
      } else {
        window.open(`${CONSULT_URL}/console/projects`, "_self");
      }
    } catch (err) {
      console.log('Login Error', err);
    }
  }

  return (
    <div>
      <h2 className={s.header}>Log In</h2>
      {/* <div className={s.signUpBtn}>
        <Button color={'secondary'}>
        <GoogleLogin
          onSuccess={handleGoogleLogin}
        />
        </Button>
      </div>
      <div className={s.continueWithText}>or continue with</div> */}
      <Formik
        enableReinitialize={true}
        initialValues={{ email: '', password: '' }}
        validationSchema={Yup.object({
          email: Yup.string()
            .matches(
              /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
              'Enter correct email!'
            )
            .required('Please enter email'),
          password: Yup.string().required('Please enter password'),
        })}
        validateOnBlur
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        <Form className={''}>
          <div className={s.signUpInputsBlock}>
            <div className={s.loginInputWrapper}>
              <FormInput name="email" placeholder="Email" />
            </div>
            <div className={s.loginInputWrapper}>
              <FormInput
                name="password"
                placeholder="Password"
                type="password"
              />
            </div>
          </div>
          <div className={s.signUpBtn}>
            <Button type="submit">Continue</Button>
          </div>
        </Form>
      </Formik>
      <div className={s.signUpBottomText}>
        Already have an account?{' '}
        <span onClick={() => props.onSwitchToSignUp()}>Sign Up</span>
      </div>
      <div className={s.signUpBottomSmallText}>
        By clicking "Sign Up" you agree to AutoFunnel’s{' '}
        <a href="#">Privacy Policy</a> and
        <a href="#">Terms of Service</a>.
      </div>
    </div>
  )
}

export default LogIn;
