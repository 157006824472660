import React, { useEffect, useState } from 'react';
import Button from '../../Common/Button/Button';
import s from './SignUpCognito.module.scss';
import { Formik, Form } from 'formik';
import { FormInput } from 'Components/Common/Inputs/Input';
import * as Yup from 'yup';
import {
  eventEmiter,
  rxPageSaved,
} from '../../../rx/rxState';
import { useObservable } from 'utils/UseObservable';
import Spinner from 'Components/Common/Spinner/Spinner';
import { ICognitoValues } from 'types/signup';

interface IProps {
  onSubmit: (values: ICognitoValues) => void;
  onSwitchToLogIn: () => void;
  email: string;
  firstName: string;
}

interface IEventBus {
  type: string;
}

const SignUp = (props: IProps) => {
  const [values, setValues] = useState<ICognitoValues | undefined>();
  const [loading, setLoading] = useState(false);
  const eventBus = useObservable(eventEmiter);

  //For tracking conversion since signup is happening AFTER stripe
  //window.conversion() //aws commented cause page broke. todo fix functionality

  useEffect(() => {
    if (rxPageSaved && values) {
      setLoading(false);
      props.onSubmit(values);
    }
  }, [eventBus])

  const handleSignUp = async (values: ICognitoValues) => {
    setLoading(true);
    const { email, password, firstName, lastName} = values;
    const name = `${firstName} ${lastName}`;
    setValues(values);
    props.onSubmit(values);
    
  }

  return (
    <div>
      <h2 className={s.header}>Congratulations!</h2>
        <div className={s.continueWithText}>Finish setting up your account</div>
        <Formik
          enableReinitialize={true}
          initialValues={{ email: props.email, password: '', firstName: props.firstName, lastName: '' }}
          validationSchema={Yup.object({
            email: Yup.string()
              .matches(
                /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/,
                'Enter correct email!'
              )
              .required('Please enter email'),
            password: Yup.string()
              .required('Password is required')
              .min(8, 'Please, enter minimum 8 characters'),
              // .matches(
              //   RegExp('(.*[a-z].*)'),
              //   'Please, enter lowercase characters'
              // )
              // .matches(
              //   RegExp('(.*[A-Z].*)'),
              //   'Please, enter uppercase characters'
              // ),
              // .matches(RegExp('(.*\\d.*)'), 'Please, enter numbers')
              // .matches(
              //   RegExp('[!@#$%^&*(),.?":{}|<>]'),
              //   'Please, enter special characters'
              // ),
            firstName: Yup.string().required(),
            lastName: Yup.string().required(),
          })}
          validateOnBlur
          onSubmit={(values) => {
            handleSignUp(values)
          }}
        >
          <Form className={''}>
            <div className={s.signUpInputsBlock}>
              <div className={s.signUpInputWrapper}>
                <FormInput name="firstName" placeholder="First Name" />
              </div>
              <div className={s.signUpInputWrapper}>
                <FormInput name="lastName" placeholder="Last Name" />
              </div>
              <div className={s.signUpInputWrapper}>
                <FormInput name="email" placeholder="Email" />
              </div>
              <div className={s.signUpInputWrapper}>
                <FormInput
                  name="password"
                  placeholder="Password"
                  type="password"
                />
              </div>
            </div>
            {!loading && <div className={s.signUpBtn}>
              <Button type="submit">Sign Up</Button>
            </div>}
            {loading && <div className={s.spinnerBlock}>
              <Spinner size={36}/>
            </div>}
          </Form>
        </Formik>
        <div className={s.signUpBottomText}>
          Already have an account?{' '}
          <span onClick={() => props.onSwitchToLogIn()}>Log In</span>
        </div>
        <div className={s.signUpBottomSmallText}>
          By clicking "Sign Up" you agree to AutoFunnel’s
          <a href="#">Privacy Policy</a> and <a href="#">Terms of Service</a>.
        </div>
    </div>
  )
}

export default SignUp;
