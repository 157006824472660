import React, {
  useState,
  useRef,
  useEffect,
  useReducer,
  useLayoutEffect,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  IBookChapter,
  IBookData,
  IGeneratedChapter,
} from 'Components/books/types';
import { AUTH_USER_TOKEN_KEY } from 'Constants';
import s from './BookGenerator.module.scss';
import Spinner from 'Components/Common/Spinner/Spinner';
import Input from 'Components/books/Input/Input';
import TextArea from 'Components/books/TextArea/TextArea';
import Select from 'Components/books/Select/Select';
import Button from 'Components/Common/Button/Button';
import logoBeta from '../Assets/logoBeta.svg';
import { graphQlCall } from 'graphql/utils';
import queries from 'graphql/queries';

import { SOCKET_URL } from 'Constants';
import { io, Socket } from 'socket.io-client';
import Slider from 'Components/books/Slider/Slider';
import BookInfoBlock from 'Components/books/BookInfoBlock/BookInfoBlock';
import BookEditorView from 'Components/books/BookEditorView';
import { fetchSectionsMenu } from 'rx/actions/rxFetchSectionsMenu';
import { AIBookGenerator } from 'Components/books/bookGeneration';
import {
  rxBlocks,
  rxNewChapterIndex,
  rxChapterIndexForDelete,
  rxSideMenu,
  rxBookDataForSave,
  rxBookNeedSave,
  rxChapterRenameData,
} from 'rx/rxState';
import { useObservable } from 'utils/UseObservable';
import Toolbar from 'Components/books/Toolbar/Toolbar';
import SaveSpinner from 'Components/Common/SaveSpinner/SaveSpinner';

const aviableTones = [
  'Professional',
  'Semi-Professional',
  'Conversational',
  'Relaxed',
  'Technical',
  '<split>',
  'Humorous',
  'Serious',
  'Sarcastic',
  'Melancholic',
  'Ironic',
  'Cynical',
  'Satirical',
  'Whimsical',
  'Hopeful',
  'Dark',
  'Uplifting',
  'Nostalgic',
  'Witty',
  'Regretful',
  'Gothic',
  'Optimistic',
  'Formal',
  'Casual',
  'Apathetic',
  'Enthusiastic',
  'Bittersweet',
  'Sincere',
  'Sarcastic',
  'Mysterious',
  'Dispassionate',
  'Sentimental',
  'Provocative',
];

function extractChapterTitle(string: string) {
  const chapterRegex = /^(?:\w+\s+)*Chapter\s+(\d+):?\s+(.+)/;
  const match = string.match(chapterRegex);

  if (match) {
    return match[2];
  } else {
    return string;
  }
}

const BookGenerator = () => {
  let history = useHistory();
  const { bookId } = useParams<any>();

  const [bookTitle, setBookTitle] = useState('');
  const [bookDescription, setBookDescription] = useState('');
  const [tone, setTone] = useState<string | null>(null);
  const [bookData, setBookData] = useState<IBookData | null>(null);

  const [titleHasError, setTitleHasError] = useState(false);
  const [descriptionHasError, setDescriptionHasError] = useState(false);
  const [bookAudience, setBookAudience] = useState('');
  const [bookStructure, setBookStructure] = useState('');
  const [bookAudienceHasError, setBookAudienceHasError] = useState(false);
  const [bookStructureHasError, setBookStructureHasError] = useState(false);
  const [step, setStep] = useState<number>(1);

  const [loading, setLoading] = useState(false);
  const [loadingChapter, setLoadingChapter] = useState(false);
  const [loadingBookImg, setLoadingBookImg] = useState(false);
  const [loadingAddChapter, setLoadingAddChapter] = useState(false);

  const [bookImg, setBookImg] = useState<string[]>([]);
  const [selectBookImg, setSelectBookImg] = useState<string | null>();

  const chapterPositionForGql = useRef<number[]>([]);

  const [, toggle] = useReducer((p) => !p, true);

  const socket = useRef<Socket | null>(null);
  const indexGenarateChapterText = useRef<number>(0);
  const lastChapter = useRef<IGeneratedChapter | null>(null);
  const [isGeneratedAllBookEnd, setIsGeneratedAllBookEnd] =
    useState<boolean>(true);

  const [chapterIndexForStartGenerateByOne, setChapterIndexForStartGenerateByOne] = useState<number | null>(null);

  const [socketStatus, setSocketStatus] = useState('closed');
  const [isNeedEdit, setIsNeedEdit] = useState(true);
  const blockSections: any = useObservable(rxSideMenu);
  const bookDataForSave: any = useObservable(rxBookDataForSave);
  const [bookSaveLoading, setBookSaveLoading] = useState<boolean>(false);
  const [generatedBookCover, setGeneratedBookCover] = useState<string | null>(null);

  const token = localStorage.getItem(AUTH_USER_TOKEN_KEY);

  useLayoutEffect(() => {
    if (bookId) {
      setIsNeedEdit(false);
    }
  }, []);
  useEffect(() => {
    if (!selectBookImg) {
      setSelectBookImg(bookImg[0]);
    }
  }, [bookImg]);

  useEffect(() => {
    if (bookDataForSave) {
      saveBook(bookDataForSave);
    }
  }, [bookDataForSave]);

  useEffect(()=>{
    if (chapterIndexForStartGenerateByOne && bookData) {
    
      if (chapterIndexForStartGenerateByOne < bookData?.chapters.length) {
        socket.current?.emit('regenerate-chapter-text', {
          bookId: bookData?._id,
          token: token,
          chapterIndex: chapterIndexForStartGenerateByOne
        });
      } else {
        setIsGeneratedAllBookEnd(true)
      }
      
    }
  },[chapterIndexForStartGenerateByOne])

  useLayoutEffect(() => {
    if (bookId) {
      const getBookDataById = async () => {
        const bookDataById: IBookData = await graphQlCall({
          queryTemplateObject: queries.GET_BOOK_BY_ID,
          headerType: 'USER-AUTH',
          values: {
            id: bookId,
          },
        });

        if (bookDataById) {
          setBookData({
            _id: bookDataById._id,
            title: bookDataById.title,
            tone: bookDataById.tone,
            chapters: bookDataById.chapters,
            audience: bookDataById.audience,
            structure: bookDataById.structure,
            description: bookDataById.description,
            coverImageUrl: bookDataById.coverImageUrl,
            data: bookDataById.data,
          });

          if (bookDataById.coverImageUrl) {
            setGeneratedBookCover(bookDataById.coverImageUrl);
          }
        }
      };
      getBookDataById();
    }
  }, []);

  useEffect(() => {
    if (selectBookImg) {
      setGeneratedBookCover(selectBookImg)
    }
  }, [selectBookImg])

  useLayoutEffect(() => {
    if (!isNeedEdit && socketStatus === 'connected') {
      if (blockSections && blockSections.length > 0) {
        if (bookData?.chapters && chapterPositionForGql) {
          let indexChapterForGenerate = 0
          bookData.chapters.forEach((el:IBookChapter, index)=>{
            if (el.text) {
              indexChapterForGenerate = index+1
            }
          })
          if (indexChapterForGenerate > 0) {
            handleGenerateBook(false,indexChapterForGenerate)
          } else {
            handleGenerateBook(false)
          }
          chapterPositionForGql.current = bookData?.chapters.map(
            (el: IBookChapter, index) => {
              return index;
            }
          );
        }
      }
    }
  }, [isNeedEdit, socketStatus, blockSections]);

  useEffect(() => {
    if (socketStatus === 'closed') {
      socket.current = io(SOCKET_URL);
      socket.current.on('connect', () => {
        console.log('connect');

        setSocketStatus('connected');
      });
    }
  }, []);

  useEffect(() => {
    if (bookData && socket.current) {
      socket.current.on('book-generated', (data) => {
        const newBookData = { ...bookData };
        const chapters = data.chapters.map((chapter: string, index: number) => {
          chapterPositionForGql.current.push(index);
          return {
            title: chapter,
            text: '',
            comments: [],
          };
        });
        newBookData.chapters = chapters;
        setBookData(newBookData);
        setLoadingChapter(false);
        // console.log('chapters:', data.chapters);
      });

      socket.current.on('chapter-title-regenerated', (data) => {
        console.log('title:', data.title);
      });

      socket.current.on('chapter-text-regenerated', (data) => {
        if (chapterIndexForStartGenerateByOne) {
          onChapterTextAddForStartGenerateByOne(data.chapterIndex, data.text)
        }
        console.log('chapter text:', data.text);
      });

      socket.current.on('image-generated', (data) => {
        setLoadingBookImg(false);
        setBookImg(data.images);
        setGeneratedBookCover(data.images[0]);
      });

      socket.current.on('chapter-generated', (data) => {
        if (!data?.chapter) {
          return;
        }
        lastChapter.current = data as IGeneratedChapter;        
        onChapterTextEditedDuringStartBookGenerate(data.chapter, data.title)
        toggle();
      });

      socket.current.on('chapter-added', (data) => {
        const newBookData = { ...bookData };

        const newChapterPositionForGql: number[] = [];
        const newChapters = data.chapters.map(
          (el: IBookChapter, index: number) => {
            newChapterPositionForGql.push(index);
            el.title = extractChapterTitle(el.title);

            return el;
          }
        );
        newBookData.chapters = newChapters;
        setBookData(newBookData);

        chapterPositionForGql.current = newChapterPositionForGql;

        setLoadingAddChapter(false);
      });
    }
  }, [bookData,chapterIndexForStartGenerateByOne]);

  useEffect(() => {
    if (lastChapter.current && bookData) {
      const chapterText = lastChapter.current.chapter;
      const newBookData = { ...bookData };
      const newChapters = [...newBookData.chapters];

      newChapters[indexGenarateChapterText.current].text = chapterText;
      newBookData.chapters = newChapters;
      indexGenarateChapterText.current = indexGenarateChapterText.current + 1;
      setBookData(newBookData);
      console.log('start effect');
      addChapterToEditor();

      if (indexGenarateChapterText.current === bookData.chapters.length) {
        setIsGeneratedAllBookEnd(true);
      }
    }
  }, [lastChapter.current]);

  const saveBook = async (dataForSave: any) => {
    setBookSaveLoading(true);
    await graphQlCall({
      queryTemplateObject: queries.UPDATE_BOOK_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        id: bookData?._id,
        data: JSON.stringify(dataForSave),
      },
    });
    setBookSaveLoading(false);
    rxBookNeedSave.next(false);
  };

  const fetchChapters = async () => {
    if (!checkFields()) {
      return;
    }
    setLoading(true);

    const createBookData: IBookData = await graphQlCall({
      queryTemplateObject: queries.CREATE_BOOK_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        tone: tone,
        description: bookDescription,
        title: bookTitle,
      },
    });

    setBookData(createBookData);
    setLoading(false);
  };

  const handleNameInput = (value: string) => {
    setTitleHasError(false);
    setBookTitle(value);
  };

  const handleDescriptionInput = (value: string) => {
    setDescriptionHasError(false);
    setBookDescription(value);
  };

  const checkFields = () => {
    let valid = true;
    if (bookDescription.trim().length === 0) {
      setDescriptionHasError(true);
      valid = false;
    }
    if (bookTitle.trim().length === 0) {
      setTitleHasError(true);
      valid = false;
    }
    return valid;
  };

  const handleNext = async () => {
    if (step === 1) {
      await fetchChapters();
      if (!checkFields()) {
        return;
      }
      setStep(2);
    } else if (step === 2) {
      if (bookAudience.trim() || bookStructure.trim()) {        
        await addAudienceAndStructure();
      } else {
        skipAddAudienceAndStructure()
      }
      setStep(3);
    }
  };

  const addAudienceAndStructure = async () => {
    setLoading(true);
    const updateBookData: IBookData = await graphQlCall({
      queryTemplateObject: queries.UPDATE_BOOK_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        id: bookData?._id,
        audience: bookAudience,
        structure: bookStructure,
      },
    });
    setBookData(updateBookData);
    if (bookData?._id) {
      setLoadingChapter(true);
      setLoadingBookImg(true);
      socket.current?.emit('book-generator', {
        bookId: bookData?._id,
        token: token,
      });
      socket.current?.emit('generate-image', {
        bookId: bookData?._id,
        token: token,
      });
    }
    setLoading(false);
  };

  const skipAddAudienceAndStructure = () => {
    if (bookData?._id) {
      setLoadingChapter(true);
      setLoadingBookImg(true);
      socket.current?.emit('book-generator', {
        bookId: bookData?._id,
        token: token,
      });
      socket.current?.emit('generate-image', {
        bookId: bookData?._id,
        token: token,
      });
      setStep(3);
    }
  };

  const handleBookAudienceInput = (value: string) => {
    setBookAudienceHasError(false);
    setBookAudience(value);
  };

  const handleBookStructureInput = (value: string) => {
    setBookStructureHasError(false);
    setBookStructure(value);
  };

  const onBookTitleEdited = async (value: string) => {
    if (value.trim().length === 0) {
      return;
    }
    const updateBookData: IBookData = await graphQlCall({
      queryTemplateObject: queries.UPDATE_BOOK_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        id: bookData?._id,
        title: value,
      },
    });
    setBookData(updateBookData);
    rxChapterRenameData.next({ index: 0, newText: value } as any);
  };

  const onChapterTitleEdited = async (value: string, index: number) => {
    if (value.trim().length === 0) {
      return;
    }
    const updateChapterData: IBookChapter = await graphQlCall({
      queryTemplateObject: queries.UPDATE_CHAPTER_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        bookId: bookData?._id,
        index: index,
        title: value,
      },
    });
    if (bookData) {
      const newBookData = { ...bookData };
      newBookData.chapters[index] = updateChapterData;
      setBookData(newBookData);
      rxChapterRenameData.next({ index: index + 1, newText: value } as any);
    }
  };

  const onChapterTextEditedDuringStartBookGenerate =  (value: string, titleForSearch: string) => {
    let indexForGql = 0
    bookData?.chapters.forEach((el:IBookChapter, index)=>{
      if (el.title === titleForSearch) {        
        indexForGql = index
      }
    })    
   graphQlCall({
      queryTemplateObject: queries.UPDATE_CHAPTER_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        bookId: bookData?._id,
        index: indexForGql,
        text: value,
      },
    });
  };

  const onChapterTextAddForStartGenerateByOne = (indexChapter:number, textChapter:string) =>{
    if (bookData) {
      const newBookData = { ...bookData };
      const newChapters = [...newBookData.chapters];
      newChapters[indexChapter].text = textChapter;
      newBookData.chapters = newChapters;
      setBookData(newBookData);
      if (chapterIndexForStartGenerateByOne ) {
        indexGenarateChapterText.current = indexChapter + 1
        setChapterIndexForStartGenerateByOne(indexChapter + 1)
        addChapterToEditor()
      } 
    }
  }
  const onChapterAdd = async (
    indexBefore: number,
    action: 'add' | 'insert'
  ) => {
    if (bookData) {
      setLoadingAddChapter(true);
      socket.current?.emit('add-chapter', {
        bookId: bookData?._id,
        token: token,
        positionBefore: indexBefore,
        action: action,
      });
    }
  };

  const deleteChapter = async (index: number) => {
    const deleteChapterData = await graphQlCall({
      queryTemplateObject: queries.DELETE_CHAPTER_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        bookId: bookData?._id,
        index: index,
      },
    });
    if (deleteChapterData.message === 'chapter was deleted') {
      rxChapterIndexForDelete.next(index as any);
      if (bookData) {
        const newBookData = { ...bookData };
        const newChapters = [...newBookData.chapters];
        newChapters.splice(index, 1);
        newBookData.chapters = newChapters;

        setBookData(newBookData);

        const newChapterPositionForGql: number[] = [];
        newBookData.chapters.forEach((el: any, index) => {
          newChapterPositionForGql.push(index);
        });
        chapterPositionForGql.current = newChapterPositionForGql;
      }
    }
  };

  const reorderChapter = (dragIndex: number, dropIndex: number) => {
    if (bookData) {
      const newBookData = { ...bookData };
      let newChapters = [...newBookData.chapters];
      const tempChapter = newChapters.splice(dragIndex, 1);
      newChapters.splice(dropIndex, 0, tempChapter[0]);
      newBookData.chapters = newChapters;
      setBookData(newBookData);

      const newChapterPositionForGql = [...chapterPositionForGql.current];
      const tempChapterIndex = newChapterPositionForGql.splice(dragIndex, 1);
      newChapterPositionForGql.splice(dropIndex, 0, tempChapterIndex[0]);
      chapterPositionForGql.current = newChapterPositionForGql;
    }
  };

  const endReorderChapter = async () => {
    const reorderChaptersData = await graphQlCall({
      queryTemplateObject: queries.REORDER_CHAPTERS_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        bookId: bookData?._id,
        indexes: chapterPositionForGql.current,
      },
    });
    if (bookData) {
      rxNewChapterIndex.next(chapterPositionForGql.current as any);
      const newBookData = { ...bookData };
      newBookData.chapters = reorderChaptersData;
      setBookData(newBookData);
      const newChapterPositionForGql = newBookData.chapters.map(
        (el: any, index: number) => {
          return index;
        }
      );
      chapterPositionForGql.current = newChapterPositionForGql;
    }
  };

  const onNoteAdd = async (chapterIndex: number, noteText: string) => {
    const updateChapterData: IBookChapter = await graphQlCall({
      queryTemplateObject: queries.UPDATE_CHAPTER_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        bookId: bookData?._id,
        index: chapterIndex,
        comments: [noteText],
      },
    });
    if (bookData) {
      const newBookData = { ...bookData };
      newBookData.chapters[chapterIndex] = updateChapterData;
      setBookData(newBookData);
    }
  };

  const handleGenerateBook = (needRedirect: boolean, indexChapterForGenerate?:number) => {
    let isGeneratedStart = false
    if (selectBookImg) {
      setGeneratedBookCover(selectBookImg);
    }
    if (needRedirect) {
      history.push(`/edit/book-editor/${bookData?._id}`);
    }
    if (selectBookImg) {
      setGeneratedBookCover(selectBookImg);
      graphQlCall({
        queryTemplateObject: queries.SET_COVER_IMAGE_MUTATION,
        headerType: 'USER-AUTH',
        values: {
          bookId: bookData?._id,
          coverImageUrl: selectBookImg,
        },
      });
    }
    if (
      bookData &&
      bookData.data &&
      bookData.data.blocks &&
      bookData.data.lastId &&
      bookData.data.blocks.length === bookData.chapters.length + 1
    ) {
      // console.log('dataHave', bookData.data);

      const dataForView = {
        blocks: bookData.data.blocks,
        lastId: bookData.data.lastId,
      };
      rxBlocks.next(dataForView as any);
    } else {
      addChapterToEditor();
    }
    let bookChaptersHaveAllText = true;

    bookData?.chapters.map((el: IBookChapter) => {
      if (!el.text) {
        bookChaptersHaveAllText = false;
      }
    });
    if (bookChaptersHaveAllText && bookData?.chapters) {
      indexGenarateChapterText.current = bookData?.chapters.length - 1;
    }

    if (socket.current && bookData?.chapters && !bookChaptersHaveAllText && !indexChapterForGenerate) {
      isGeneratedStart = true
      socket.current.emit('book-chapters-generator', {
        chapters: bookData?.chapters,
        bookId: bookData._id,
        token: token,
      });
    }
    if (!bookChaptersHaveAllText) {
      setIsGeneratedAllBookEnd(false);
    }
    if (indexChapterForGenerate && !isGeneratedStart && !bookChaptersHaveAllText) {
      indexGenarateChapterText.current = indexChapterForGenerate
      setChapterIndexForStartGenerateByOne(indexChapterForGenerate)
    }
    setStep(4);
  };

  useEffect(() => {
    fetchSectionsMenu();
  }, []);

  const addChapterToEditor = async () => {
    if (bookData?.chapters) {
      // console.log('addChapterToEditor', bookData?.chapters);

      const newGeneratedChapters = bookData?.chapters.reduce<
        IGeneratedChapter[]
      >((acc, el: IBookChapter, index) => {
        if (el.text) {
          acc.push({
            title: el.title,
            chapter: el.text,
            bookName: bookData.title,
          });
        }
        return acc;
      }, []);

      let result: any = {};
      console.log('BOOK DATA', bookData);
      if (bookData) {
        result = AIBookGenerator.build({
          chapters: newGeneratedChapters,
          coverUrl: generatedBookCover,
          bookTitle: bookData.title,
        });
      } else {
        result = AIBookGenerator.build({
          chapters: newGeneratedChapters,
        });
      }
      console.log('result:', result);
      rxBlocks.next(result);
    }
  };

  if (step === 1 && isNeedEdit) {
    return (
      <div className={s.bookPageContent}>
        <div className={s.bookPageLogoBlock}>
          <img src={logoBeta} alt="logo" />
          <div>
            Create a book in 30 seconds with our artificial intelligence
          </div>
        </div>
        <h2 className={s.bookPageHeader}>{'What is this book about?'}</h2>
        {loading ? (
          <Spinner size={300} />
        ) : (
          <div className={s.inputsBlock}>
            <Input
              value={bookTitle}
              label="Title"
              onChange={(value) => handleNameInput(value)}
              hasErrors={titleHasError}
            />
            <Select
              label="Tone"
              onChange={setTone}
              value={tone}
              options={aviableTones}
            />
            <TextArea
              value={bookDescription}
              label="Book Description"
              onChange={(value) => handleDescriptionInput(value)}
              hasErrors={descriptionHasError}
            />
            <div className={s.buttonBlock}>
              <Button
                size="regular"
                onClick={() => handleNext()}
                borderRadius={'25px'}
              >
                Next
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  } else if (step === 2 && isNeedEdit) {
    return (
      <div className={s.bookPageContent}>
        <div className={s.bookPageLogoBlock}>
          <img src={logoBeta} alt="logo" />
          <div>
            Create a book in 30 seconds with our artificial intelligence
          </div>
        </div>
        <h2 className={s.bookPageHeader}>{'Audience and Structure'}</h2>
        {loading ? (
          <Spinner size={300} />
        ) : (
          <div>
            <div className={s.inputsBlock}>
              <Input
                value={bookAudience}
                label="Who is your audience? (Optional)"
                onChange={(value) => handleBookAudienceInput(value)}
                hasErrors={bookAudienceHasError}
              />
              <div className={s.textAreaWrapper}>
                <TextArea
                  value={bookStructure}
                  label="Structure (Optional)"
                  onChange={(value) => handleBookStructureInput(value)}
                  hasErrors={bookStructureHasError}
                />
              </div>
              <div className={s.buttonsBlock}>
                <Button
                  size="regular"
                  onClick={() => handleNext()}
                  borderRadius={'25px'}
                >
                  Next
                </Button>
                <Button
                  size="regular"
                  color="secondary"
                  onClick={() => skipAddAudienceAndStructure()}
                  borderRadius={'25px'}
                >
                  Skip this Step
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else if (step === 3 && isNeedEdit) {
    return (
      <div style={{ display: 'flex', height: '100%' }}>
        <div className={s.bookPageGeneratorWrapper}>
          <div className={s.bookInfoBlock}>
            {/* <div className={s.bookPageLogoBlock}>
              <img src={logoBeta} alt="logo" />
              <div>Fill in Notes for Better Generation</div>
            </div> */}
            <div className={s.bookInfoBlockInner}>
              <BookInfoBlock
                bookTitle={bookData?.title ? bookData?.title : ''}
                onBookTitleEdited={onBookTitleEdited}
                chapters={bookData?.chapters ? bookData?.chapters : []}
                onChapterTitleEdited={onChapterTitleEdited}
                deleteChapter={deleteChapter}
                loadingChapter={loadingChapter}
                onChapterAdd={onChapterAdd}
                reorderChapter={reorderChapter}
                endReorderChapter={endReorderChapter}
                loadingAddChapter={loadingAddChapter}
                onNoteAdd={onNoteAdd}
                onGenerateBook={handleGenerateBook}
                isEditVersion={false}
                isGeneratedAllBookEnd={isGeneratedAllBookEnd}
              />
            </div>
          </div>
          <div className={s.bookSelectImgBlockWrapper}>
            <div className={s.bookImgBlock}>
              <Slider
                slides={bookImg.map((img) => img)}
                loading={loadingBookImg}
                textForImg={bookData?.title}
                setCurrentImg={(img: string) => setSelectBookImg(img)}
              />
              {!loadingBookImg && (
                <div className={s.bookImgBlockText}>
                  Choose the Book Cover
                  <div>If needed, you will be able to replace it later</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (step === 4 || !isNeedEdit) {
    return (
      <div className={s.bookEditorWrapper}>
        <div className={s.bookEditorSideMenu}>
          <BookInfoBlock
            bookTitle={bookData?.title ? bookData?.title : ''}
            onBookTitleEdited={onBookTitleEdited}
            chapters={bookData?.chapters ? bookData?.chapters : []}
            onChapterTitleEdited={onChapterTitleEdited}
            deleteChapter={deleteChapter}
            loadingChapter={loadingChapter}
            onChapterAdd={onChapterAdd}
            reorderChapter={reorderChapter}
            endReorderChapter={endReorderChapter}
            loadingAddChapter={loadingAddChapter}
            onNoteAdd={onNoteAdd}
            onGenerateBook={handleGenerateBook}
            isEditVersion={true}
            isGeneratedAllBookEnd={isGeneratedAllBookEnd}
            indexGenarateChapterText={indexGenarateChapterText.current}
          />
        </div>
        <div className={s.bookEditorView}>
          <BookEditorView />
        </div>
        {bookSaveLoading && <SaveSpinner />}
      </div>
    );
  } else return null;
};

export default BookGenerator;
