import QUERIES from 'graphql/queries';
import getBrowserFingerprint from 'get-browser-fingerprint';
import { graphQlCall } from 'graphql/utils';

export const sendUserEvent = ({ type, value, projectId }) => {
  const domFunnel = document.getElementById('meta-funnel-id');
  let funnelId = projectId
  if (!projectId && domFunnel) {
    funnelId = domFunnel.getAttribute('data');
  }
  if (!funnelId) {
    console.error('FunnelId not found');
    return;
  }
  const fingerprint = `${getBrowserFingerprint()}`;
  graphQlCall({
    queryTemplateObject: QUERIES.ADD_STATISTIC_EVENT,
    values: {
      funnelId,
      type,
      fingerprint,
      value: value || 1
    }
  });
};
