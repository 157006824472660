import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as LogoText } from 'Assets/LogoAndTextWithAi.svg';
import style from './AIQuestionnaire.module.scss';
import Question from './Question/Question';
import * as Yup from 'yup';
import { AIResult, GenerationParams } from 'types/ai';
import { AIGenerator } from 'page-ai-generator/generation';
import { rxBlocks, rxIsLoading } from 'rx/rxState';
import { fetchSectionsMenu } from 'rx/actions/rxFetchSectionsMenu';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import FakeLoading from 'Components/Common/FakeLoading/FakeLoading';
import { useObservable } from 'utils/UseObservable';
import { CONSULT_URL, GOOGLE_TAG } from 'Constants';
import TagManager from 'react-gtm-module';

export interface IQuestion {
  placeholder?: string;
  question: string;
  validation?: Yup.AnySchema;
  key: keyof GenerationParams | 'type' | string;
  answer?: any;
}

TagManager.initialize({
  gtmId: GOOGLE_TAG,
  dataLayer: {
    events: {
      question_1: 'question_1',
      question_2: 'question_2',
      question_3: 'question_3',
      question_4: 'question_4',
      question_5: 'question_5',
      question_6: 'question_6',
      question_7: 'question_7',
      page_generated: 'page_generated',
    }
  }
});

const questions: IQuestion[] = [
  {
    placeholder: 'Product name',
    question: 'What is your product?',
    validation: Yup.string().required('Field is required'),
    key: 'productName',
  },
  {
    placeholder: 'Description',
    question: 'What does it do?',
    validation: Yup.string().required('Field is required'),
    key: 'description',
  },
  {
    placeholder: '',
    question: 'What problem does your product solve for the user?',
    validation: Yup.string().required('Field is required'),
    key: 'problemInput',
  },
  {
    placeholder: '',
    question: 'How else does this product improve quality of life?',
    validation: Yup.string().required('Field is required'),
    key: 'advantagesInput',
  },
  // {
  //   placeholder: '',
  //   question: 'Where do you plan on marketing your product?',
  //   validation: Yup.string().required('Field is required'),
  //   key: 'add1',
  // },
  // {
  //   placeholder: '',
  //   question: "What's your ideal customer age?",
  //   validation: Yup.string().required('Field is required'),
  //   key: 'add2',
  // },
  // {
  //   placeholder: '',
  //   question: 'How much do you charge for your product?',
  //   validation: Yup.string().required('Field is required'),
  //   key: 'add3',
  // },
];

const skipFields = ['type', 'add1', 'add2', 'add3'];

const AIQuestionnaire: React.FC = ({}) => {
  const location = useLocation();
  const history = useHistory();
  const user = useRef<any>({});
  const buildProcess = useRef<Promise<any>>();
  const [error, setError] = useState('');
  let { user: userId, page, productId = 'new' } = useParams<any>();

  useEffect(() => {
    fetchSectionsMenu();
    (async () => {
      user.current = await Auth.currentUserInfo();
    })();
  }, []);

  const loading = useObservable(rxIsLoading);
  const [step, setStep] = useState(0);
  const data = useRef<Partial<GenerationParams>>({});
  const additional = useRef<any>({});

  const sendGoogleTagEvent = () => {
    let eventName = '';
    switch (step) {
      case 1:
        eventName = 'question_2';
        break;
      case 2:
        eventName = 'question_3';
        break;
      case 3:
        eventName = 'question_4';
        break;
      case 4:
        eventName = 'question_5';
        break;
      case 5:
        eventName = 'question_6';
        break;
      case 6:
        eventName = 'question_7';
        break;
    }

    if (eventName !== '') {
      TagManager.dataLayer({
        dataLayer: {
          event: eventName
        },
      });
    }
  }

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'question_1'
      },
    });
    sendGoogleTagEvent();
  }, [step])
  
  const onSubmit = async (values: { answer: any }) => {
    const skipFields = ['type', 'add1', 'add2', 'add3'];
    if (questions[step].key === 'productName') {
      localStorage.setItem('NEW_PROJECT_NAME', values.answer);
    }
    if (skipFields.includes(questions[step].key)) {
      additional.current[questions[step].key] = values.answer;
    } else {
      setError('');
      data.current[questions[step].key as keyof GenerationParams] =
        values.answer;
    }
    if (step + 1 < questions.length) {
      setStep(step + 1);
    }
    if (step + 1 === questions.length) {
      try {        
        buildProcess.current = startBuild();
        rxIsLoading.next('Generate page...');
        const newpage = await buildProcess.current;
        rxBlocks.next(newpage);
        const queryParams = new URLSearchParams(location.search);
        const projectId = queryParams.get('projectId');
        if (projectId) {
          history.push(
            `/edit/edit/${
              userId || user.current?.sub || 'unauth'
            }/${productId}/${page || 'optin'}?generated=1&projectId=${projectId}`
          );
        } else{
          history.push(
            `/edit/edit/${
              userId || user.current?.sub || 'unauth'
            }/${productId}/${page || 'optin'}?generated=1`
          );
        }
        
        TagManager.dataLayer({
          dataLayer: {
            event: 'page_generated'
          },
        });
        window.parent.postMessage({status: 'success page create'}, CONSULT_URL);
        setTimeout(() => {
          rxIsLoading.next('');
        }, 300);
      } catch (e) {
        setStep(1);
        rxIsLoading.next('');
        setError('something went wrong, please try again');
      }
    }
  };

  const startBuild = async () => {
    try {
      const result = await AIGenerator.build(data.current as AIResult);

      const newpage = {
        ...result,
      };
      return newpage;
    } catch (e) {
      console.log('Error', e);
      setError('something went wrong, please try again');
      throw e;
    }
  };

  const onPrevious = () => {
    setStep(step - 1);
  };

  const question = questions[step];

  let value;
  if (!skipFields.includes(question.key)) {
    value = data.current[question.key as keyof GenerationParams];
  } else {
    value = additional.current[question.key];
  }

  return (
    <div className={style.root}>
      <div className={style.header}>
        <LogoText />
        <div className={style.hint}>Build your business with Artificial Intelligence</div>
      </div>

      <div className={style.questionBlock}>
        {error && <div className={style.error}>{error}</div>}
        {loading !== 'Generate page...' ? (
          <>
          {/* needed for google tag manager */}
          <div id={question.key}></div>
          <Question
            key={question.key}
            onSubmit={onSubmit}
            onPrevious={onPrevious}
            question={question}
            answer={value}
            isLast={step == questions.length - 1}
          />
          </>
        ) : ( 
          <div style={{ width: '100%' }}>
            <FakeLoading />
          </div>
        )}
      </div>

      <div className={style.bottom}>
      <a href="https://autofunnel.ai/autofunnel.aitos.html" target="_blank">Terms </a>
      -
      <a href="https://autofunnel.ai/autofunnel.aiprivacy.html" target="_blank"> Privacy</a> <br/>
        AutoFunnel.ai - Copyright © 2023
      </div>
    </div>
  );
};

export default AIQuestionnaire;
